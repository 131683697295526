/* src/Navbar2.css */

.navbar {
    height: 20%;
    width: 100%;
    background-color: rgb(248, 248, 255);
}

.logo {
    width: 120px;
    height: 70px;
    margin-left: 10px;
}

.tab {
    font-family: ROBOTO;
    color: rgb(109, 108, 108);
    font-size: 17px;
    margin-left: 10px;
    margin-left: 10px;
}

.tab:hover {
    color: rgb(0, 0, 0);
    font-size: 18px;
}

.tab:current{
    font-size: 25;
}

.navbar-nav {
    margin-left: auto;
}

.button{
    margin-left: 10px;
    margin-right: 5px;
    font-size: 17px;
    font-family: ROBOTO;
    border-radius: 20px;
    height: 40px;
    width: 100px;
}

.dropdwn{
 margin-left: 5px;
 margin-right: 10px;
 border: none;
 font-size: 17px;
 font-size: ROBOTO;
 color: black;
}

.align-items-top{
    width: 70%;
    height: 50px;
}